import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Report } from '@edw-app-root/entities/report';
import { UserSettings } from '@edw-app-root/entities/user-settings';
import { UserSettingsService } from '@edw-app-root/services/user-settings.service';
import { ToastMessageService } from '@edw-services/messaging.service';
import * as _ from 'lodash';
import { Message } from 'primeng-lts';
import { Collection, CollectionData, CollectionStore } from '../../components/entities/CollectionDataClasses';
import { ExtractService } from '../../components/extracts/extract.service';
import { CollectionDataService, CollectionService, ResourceService } from '../../components/services/resource.service';

@Component({
  selector: 'edw-reports-listing',
  templateUrl: './reports-listing.component.html',
  styleUrls: ['./reports-listing.component.scss', '../../components/sharedStyles.scss'],
  providers:[ExtractService],
  encapsulation: ViewEncapsulation.None
})
export class ReportsListingComponent implements OnInit, OnDestroy {
  public userInfo = { id: '1'};
  public inProgress = false;
  public userExtracts = [];
  public loaderShown: boolean;
  public query: string;
  public messages: Message[] = [];
  public userSettings: UserSettings;
  public layoutTile: boolean;
  public CollectionStore: CollectionStore;
  public collectionData: CollectionData;
  public resourceGroupFilter = true;
  public paging = {
    reports:{
      id: 'allReports',
      page:1,
      tilesPerPage:12,
      rowsPerPage:10
    },
    extracts:{
      id:'allExtracts',
      page:1,
      tilesPerPage:12,
      rowsPerPage:10
    },

    isActive: true
  };
  // public resourceType = ResourceType;
  @ViewChild('executeExtractConfirmationModal', {static: true}) executeExtractConfirmationModal;


  constructor(
    private route: ActivatedRoute,
    private userSettingsService: UserSettingsService,
    private resourceService: ResourceService,
    private toastMessageService: ToastMessageService,
    private collectionService: CollectionService,
    private collectionDataService: CollectionDataService
  ) {
    this.collectionService.CollectionStore$.subscribe((store) => this.CollectionStore = store);
    this.collectionDataService.CollectionData.subscribe( (collectionData) => this.collectionData = collectionData);
  }

  public ngOnInit() {

    this.userSettingsService.currentUserSettings
      .subscribe(userSettings => this.userSettings = userSettings);

    this.layoutTile = (this.userSettings.pageView === 'Tile') ? true : false;

    this.route.data
      .subscribe((data: { reportData: any, userExtracts: any }) => {

        const extracts = this.resourceService.parseData(data.userExtracts);

        // Create a unique list of reports to use as a masterlist, sorted by id.
        let reports: Report[] = _.uniqBy( [].concat.apply( [], data.reportData ).sort( ( a, b ) => a.id - b.id ), 'id' );

        reports = reports.map( report => {
          if( report.ratingOptout == 'true' ){ report.calulatedRatingValue = null; report.calculatedRatingCount = null; }
          return report;
        })

        let collections = [ new Collection( -2, 'All', reports.map(report => report.id), extracts.map(extract => extract.id) ) ];

        collections = collections.concat(
          data.reportData.map(reports => reports)
            .filter((v, i, a) => a.indexOf(v) === i)
            .map((group, id) => new Collection(id, group[0].resourceGroupName, group.map(report => report.id),[]))
        );


        this.collectionService.setCollectionServiceData( reports, extracts, collections);

        this.collectionDataService.setCollectionData([this.CollectionStore.Collections[0]]);

        if (this.collectionData.Extracts.length === 0 && this.collectionData.Reports.length === 0) {
          this.messages.push({ severity: 'info', summary: '', detail: 'No results.' });
        }

      });

  }

  public ToggleResourceGroupFilter(){
    this.resourceGroupFilter = !this.resourceGroupFilter;   // toggle resourceGroupFilter;
    if(this.resourceGroupFilter === false){                 // if not filtered...
      this.collectionDataService.setCollectionData([this.CollectionStore.Collections[0]]);
    }
  }

  layoutUpdate(layout:boolean){ this.layoutTile = layout; }



  public log(val){
    //console.log(val);
  }



  public addToastMessage(message: Message){
    this.toastMessageService.addMessageObj(message);
  }

  ngOnDestroy(): void {
    this.CollectionStore = new CollectionStore();
    this.collectionData = new CollectionData();
    // this.paramsSub.unsubscribe();
    // if (this.formSub) {
    //   this.formSub.unsubscribe();
    // }

    // @non-prod
    //this.userDataSubsription.unsubscribe();
    // @/non-prod
  }
}

