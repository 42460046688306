import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Report } from '@edw-entities/report';
import { Subscription } from 'rxjs';
import { FilterInputService } from '../../../components/filter-input/filter-input.component';
import { FilterPipe, SortAlpha } from '../../../components/resource.filter';
import { CollectionDataService, CollectionService, ResourceService } from '../../../components/services/resource.service';
import { Collection, CollectionData, CollectionType, CollectionUpdateObject } from '../../entities/CollectionDataClasses';
import { EDWModal, EDWModalService } from '../../services/modal.service';
import { UserCollectionDataService } from '../../services/user-collection-data.service';

@Component({
  selector: 'edw-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['../../tileAndListStyles.scss'],
  providers: [ FilterPipe, SortAlpha ],
  encapsulation: ViewEncapsulation.None
})
export class ReportListComponent implements OnInit, OnDestroy {
  @Input() layoutTile: boolean;
  @Input() favoriteEdit = false;
  @Input() collectionEdit = false;


  subscriptions: Subscription[] = [];
  reports: Report[] = [];
  collectionData: CollectionData;
  InitialcollectionData: CollectionData;
  collection: Collection[];
  descModal: EDWModal;
  showSelected = false;
  pager = { start: 0, end: 12 }
  filterText = "";

  tileView = {
    baseCnt : 12,
    i: 1,
    filterKey: '',
    items : [],
    viewMore : ( list: any[] ) => {
      this.tileView.i+=1;
      this.tileView.filterItems(list);
    },
    setItems: ( list: any[], filterKey ) => {
      this.tileView.i = 1;
      this.tileView.filterKey = filterKey;
      this.tileView.filterItems(list);
    },
    filterItems: (list : any[]) =>{
        list = this.sortAlpha.transform( list, this.tileView.filterKey );
        this.tileView.items = list.slice( 0, this.tileView.baseCnt * this.tileView.i);

    }
  }

  constructor(
    private edwModalService: EDWModalService,
    private collectionService: CollectionService,
    private collectionDataService: CollectionDataService,
    private userCollectionDataService: UserCollectionDataService,
    private resourceService :ResourceService,
    private filterInputService:FilterInputService,
    private filterPipe: FilterPipe,
    private sortAlpha: SortAlpha
  ) {
    this.subscriptions = [

      this.collectionDataService.CollectionData.subscribe(
        collection =>{
          this.collectionData = collection;
          this.collection = collection.Collection;
          this.InitialcollectionData = { ...collection };
          this.showSelected = false;
          this.tileView.setItems( this.collectionData.Reports, 'title' );
        }
      ),

      this.filterInputService.query.subscribe(
        filter => {
          if(filter != null){

            this.collectionData.Reports = ( filter.length > 0 )
              ? this.filterPipe.transform(this.InitialcollectionData.Reports, filter, ['title', 'description' ])
              : this.collectionData.Reports;

              this.tileView.setItems( this.collectionData.Reports, 'title' );

          }
        }
      )
    ]
  }
  ngOnInit() { this.descModal = this.edwModalService.initModal(); }

  ngOnDestroy(){ this.filterInputService.clearQuery(); this.subscriptions.forEach( sub => sub.unsubscribe() ); }

  openReport(event, id){
    event.preventDefault();
    window.open( `#/app/resources/reports/${id}`);
  }

  public isInCollection(id): boolean{ return this.collection[0].reports.includes(+id); }

  public editCollectionData( resource ){
    this.userCollectionDataService.updateCollectionData( new CollectionUpdateObject( 'reports', resource, this.collection[0] ) );
  }

  public editFavorites(report:Report){
    this.collectionService.saveFavorite({resource: report, collectionType: CollectionType.reports});
  }

  toggleShowSelected(showFavorties: boolean = false){
    /* toggles showSelected flag */
    this.showSelected = !this.showSelected;

    /* if showSelected is true... filter reports*/
    if( this.showSelected ){
      /* filter by favorites or collection inclusion, based on showFavorites bool param */
      this.collectionData.Reports = ( showFavorties )
        ? this.collectionData.Reports.filter(report => report.isFavorite ) // favorites
        : this.collectionData.Reports.filter( report =>  this.collection[0].reports.includes(+report.id));

      return false;
    }
    /*if showSelected is false, put all reports back in scope.*/
    //this.reports = this.collectionData.Reports;
  }

  toggleDescModalVisbility($event){
    this.descModal = this.edwModalService.updateModal(this.descModal,[!this.descModal.showModal, $event.title, `Description: ${$event.description}`]);
  }


  addInlineStyle(val){
    return this.resourceService.addInlineStyle(val);
  }

  updatePager(event){
    this.pager.start = event.first;
    this.pager.end = event.first + event.rows;
  }

}

interface IPaginator{
  first:number,
  page:number;
  rows:number;
  pageCount:number;
}
