import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
    AccordionModule,
    AutoCompleteModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    InputSwitchModule,
    MessagesModule,
    PaginatorModule,
    SharedModule,
    TableModule,
    ToastModule
} from 'primeng-lts';

import { EDWSharedModule } from '@edw-app-root/shared/shared.module';

import { LookupUserAccountComponent } from './components/lookup-user-account/lookup-user-account.component';
import { MergeUserAccountComponent } from './components/merge-user-account/merge-user-account.component';
import { ManagePeopleRouteGuard } from './people.guard';
import { ManagePeopleRoutingResolver } from './people.resolver';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserGroupDetailComponent } from './user-group-detail/user-group-detail.component';
import { UserGroupsListingComponent } from './user-groups-listing/user-groups-listing.component';
import { UserListingComponent } from './user-listing/user-listing.component';

export const peopleRoutes = [
  {
    path: 'users',
    canActivate: [ManagePeopleRouteGuard],
    canActivateChild: [ManagePeopleRouteGuard],
    children: [
      {
        path: '',
        resolve: {
          componentData: ManagePeopleRoutingResolver
        },
        component: UserListingComponent,
      },
      {
        path: 'add',
        resolve: {
          componentData: ManagePeopleRoutingResolver
        },
        component: UserDetailComponent
      },
      {
        path: ':id',
        resolve: {
          componentData: ManagePeopleRoutingResolver
        },
        component: UserDetailComponent
      }
    ]
  },
  {
    path: 'user-groups',
    canActivate: [ManagePeopleRouteGuard],
    canActivateChild: [ManagePeopleRouteGuard],
    children: [
      {
        path: '',
        resolve: {
          componentData: ManagePeopleRoutingResolver
        },
        component: UserGroupsListingComponent,
      },
      {
        path: 'add',
        resolve: {
          componentData: ManagePeopleRoutingResolver
        },
        component: UserGroupDetailComponent
      },
      {
        path: ':id',
        resolve: {
          componentData: ManagePeopleRoutingResolver
        },
        component: UserGroupDetailComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    EDWSharedModule,
    TableModule,
    SharedModule,
    AutoCompleteModule,
    PaginatorModule,
    DialogModule,
    AccordionModule,
    MessagesModule,
    ToastModule,
    DropdownModule,
    CheckboxModule,
    InputSwitchModule
  ],
  declarations: [
    UserGroupsListingComponent,
    UserGroupDetailComponent,
    UserListingComponent,
    UserDetailComponent,
    MergeUserAccountComponent,
    LookupUserAccountComponent
  ],
  providers: [
    ManagePeopleRoutingResolver,
    ManagePeopleRouteGuard
  ],
  exports: [
    UserGroupsListingComponent,
    UserGroupDetailComponent,
    UserListingComponent,
    UserDetailComponent
  ]
})
export class PeopleModule { }
