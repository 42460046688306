import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handler.service';
import { UserDataService } from '../user-data.service';


@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private sessionStorageService: SessionStorageService,
    private errorHandlerService : ErrorHandlerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userDataService = this.injector.get(UserDataService);

    const newHeaders = new HttpHeaders({
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '-1',
      
    });

    var requestCopy = req.clone({
      withCredentials: true,
      headers: newHeaders
    });

    return next.handle(requestCopy)
      .pipe(
        catchError(response => {
        if (response instanceof HttpErrorResponse) {

          if (response.status === 401) {
            const router = this.injector.get(Router);
            router.navigate(['/login']);
            this.errorHandlerService.httpErrorHandler(response);
            return EMPTY;
          }

          if (response.status === 403) {
            this.sessionStorageService.add('message-error', 'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.');
          }
        }

        return next.handle(requestCopy);
      }));
  }

}
