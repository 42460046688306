import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from '@edw-app-root/entities/account';
import { PurplePageUserDTO } from '@edw-app-root/entities/purple-page-user';
import { User } from '@edw-app-root/entities/user';
import { AuthenticationDomains } from '@edw-app-root/enums/auth-domains';
import { ErrorHandlerService } from '@edw-app-root/services/error-handler.service';
import { LoaderService } from '@edw-app-root/services/loader.service';
import { UserDataService } from '@edw-app-root/services/user-data.service';


@Component({
  selector: 'edw-lookup-user-account',
  templateUrl: './lookup-user-account.component.html',
  styleUrls: ['./lookup-user-account.component.scss']
})
export class LookupUserAccountComponent implements OnInit {
  @Input() displayDialog = false;
  @Output() displayDialogChange = new EventEmitter<boolean>();


  @Output() mergeResult = new EventEmitter<string>();

  selectedUser: PurplePageUserDTO[] = [];
  users: PurplePageUserDTO[] = [];
  isLoading = false;
  doesExist = false;

  searchById = false;
  searchBarPlaceHolder = "Search by first and last name";

  constructor(private userDataService: UserDataService, private router: Router, private errorService: ErrorHandlerService, private loader: LoaderService) { }

  ngOnInit() {
  }

  search(event) {
    //do not perform a search unless chars are greater than 3
    if (event.query.length <= 3) {
      return;
    }

    this.isLoading = true;

    if (this.searchById) {
      //trim NM from query
      if (event.query.toLowerCase().startsWith("nm")) {
        event.query = event.query.substring(2);
      }
    }
    this.userDataService.purplePagesGetAll(event.query, this.searchById)
      .subscribe((response: { data: PurplePageUserDTO[] }) => {
        this.isLoading = false;
        this.users = response.data.map(u => {
          u.displayName = u.firstName + " " + u.lastName + " (" + u.emailAddress + ")";
          return u;
        }
          , error => {
            this.isLoading = false;
            console.log("there is an error connecting to purple pages api.")
          })
      });
  }

  close() {
    this.isLoading = false;
    this.displayDialog = false;
    this.doesExist = false;
    this.displayDialogChange.emit(false);
  }


  checkIfUserExist(event: PurplePageUserDTO) {

    this.userDataService.doesUserExist(event.userId, AuthenticationDomains.nmhc, event.emailAddress)
      .subscribe((response: { data: any }) => {
        if (response.data === true) {
          this.doesExist = true;
        }
        else
          this.doesExist = false;
      })

  }

  addUser() {
    this.loader.showLoader();
    this.userDataService.addUser(<User>({
      email: this.selectedUser[0].emailAddress,
      firstName: this.selectedUser[0].firstName,
      lastName: this.selectedUser[0].lastName,
      accounts: [
        new Account({ activeDirectory: AuthenticationDomains.nmhc, accountName: this.selectedUser[0].userId })
      ]
    }))
      .subscribe(response => {
        if (response['data'] && parseInt(response['data'])) {
          this.close();
          this.router.navigate(['/app/manage/users', response['data']]);
        }
      }, httpErrorResponse => {
        this.errorService.httpErrorHandler(httpErrorResponse);
        this.loader.hideLoader();
      });
  }

  checkValue(event) {
    this.searchBarPlaceHolder = this.searchById ? 'Search by NM User ID' : "Search by first and last name";
  }

}
