import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { IRestResponse } from '@edw-entities/rest-response';
import { SearchParams } from '@edw-app-root/entities/searchParams';
import {
  IResearchRequestListItem,
  IResearchRequest,
  IResearchRequestOperations,
  IResearchRequestStatus,
  IAttachment,
  IAttachmentResponse
} from '@edw-entities/research-request';
import { map } from 'rxjs/operators';
import { IActivityDetail } from '@edw-app-root/entities/research-activity-type';

@Injectable()
export class ResearchRequestsService {

  private endpoint: string;

  constructor(private http: HttpClient) {
    this.endpoint = environment.api;
  }

  getAllResearchRequests(
    filterTerm: string = '',
    onlyMine: boolean = false,
    onlyActive: boolean = true,
    page: number = 1
  ): Observable<IResearchRequestListItem[]> {

    let params = new HttpParams();
    params = params.set('filterTerm', filterTerm);
    params = params.set('onlyMine', onlyMine.toString());
    params = params.set('onlyActive', onlyActive.toString());
    params = params.set('page', page.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http.get<IResearchRequestListItem[]>(`${this.endpoint}/research-requests/getAll`, options);
  }

  createResearchRequest(data: Partial<IResearchRequest>): Observable<IResearchRequestListItem> {
    const options: Object = {
      observe: 'body'
    };
    //console.log("data");
    //console.log(data);
    // console.log(options);
    // return null;
    return this.http.post<IResearchRequestListItem>(`${this.endpoint}/research-requests/add`, data, options);
  }

  submitForApproval(id: number, stewards: number[]): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post<any>(`${this.endpoint}/research-requests/${id}/approval`, stewards, options);
  }

  reSubmitForApproval(id: number): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post<any>(`${this.endpoint}/research-requests/${id}/resubmitApproval`, null, options);
  }

  updateResearchRequest(data: Partial<IResearchRequest>): Observable<IResearchRequest> {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post<IResearchRequest>(`${this.endpoint}/research-requests/${data.id}/update`, data, options);
  }

  getResearchRequestDetails(rid: string): Observable<IResearchRequest> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get<IResearchRequest>(`${this.endpoint}/research-requests/${rid}`, options);
  }

  deidentifyResearchRequest(rid: string): Observable<IResearchRequestOperations> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<IResearchRequestOperations>(`${this.endpoint}/research-requests/${rid}/deIdentify`, {}, options);
  }

  getResearchRequestOperations(rid: string): Observable<IResearchRequestOperations> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.get<IResearchRequestOperations>(`${this.endpoint}/research-requests/${rid}/operations`, options);
  }

  getResearchRequestStatus(rid: string): Observable<IResearchRequestStatus> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.get<IResearchRequestStatus>(`${this.endpoint}/research-requests/${rid}/status`, options);
  }

  getAllResearchRequestsByUser(
    filterTerm: string = '',
    // onlyActive: boolean = true,
    page: number = 1
  ): Observable<IResearchRequestListItem[]> {

    let params = new HttpParams();
    params = params.set('filterTerm', filterTerm);
    params = params.set('page', page.toString());
    // params = params.set('isActive', onlyActive.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http.get<IResearchRequestListItem[]>(`${this.endpoint}/research-requests/getAllbyUser`, options);
  }

  releaseAgreementToUser(rid: string): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<any>(`${this.endpoint}/research-requests/${rid}/releaseagreement`, {}, options);
  }

  markAsDelivered(rid: string): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<any>(`${this.endpoint}/research-requests/${rid}/markdelivered`, {}, options);
  }

  markAsClosed(rid: string, isClosed: boolean): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<any>(`${this.endpoint}/research-requests/${rid}/close`, isClosed ? 1 : 0, options);
  }

  uploadAttachments(rid: string, formData: FormData): Observable<IAttachmentResponse> {
    return this.http.post<IAttachmentResponse>(`${this.endpoint}/research-requests/${rid}/attachment/add`, formData);
  }

  removeAttachment(rid: string, attachmentId: number): Observable<IAttachmentResponse> {
    return this.http.post<any>(`${this.endpoint}/research-requests/${rid}/attachment/remove`, attachmentId);
  }

  getAttachments(rid: string): Observable<IAttachmentResponse> {
    return this.http.get<IAttachmentResponse>(`${this.endpoint}/research-requests/${rid}/attachment/getAll`);
  }

  downloadAttachment(rid: string, name: string, attachmentId: number) {
    return this.http.get(`${this.endpoint}/research-requests/${rid}/attachment/${attachmentId}/download`, {
      responseType: 'blob'
    })
      .pipe(map((res: any) => {
        return {
          filename: name,
          data: res
          // data: new Blob( [res.blob()], { type: 'application/octet-stream'} )
        };
      }));
  }

  removeResearchRequest(rid: string): Observable<any> {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/research-requests/${rid}/remove`, {}, options);
  }

  addResourceToResearchRequest = (rid: string, resources: number[]): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/research-requests/${rid}/report/add`, resources, options);
  }

  removeResourceFromResearchRequest = (rid: string, resourceId: string): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/research-requests/${rid}/report/remove`, parseInt(resourceId, 10), options);
  }

  findAddedResource = (cohortId: string, searchParams: SearchParams): Observable<any> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body'
    };

    return this.http.post(`${this.endpoint}/research-requests/${cohortId}/report/get`, postBody, options);
  }

  findNonAddedResource = (rid: string, query: string): Observable<any> => {
    const postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http.post<Response>(`${this.endpoint}/research-requests/${rid}/report/get`, postBody, options)
      .pipe(
        map((response) => {
          response['data'] = response['data'].map(element => {
            element['displayName'] = '(' + element.id + ') ' + element.resourceName;
            element['link'] = ['/app/manage/resources/deployed/', element.id];
            return element;
          });
          return response;
        })
      );
  }

  getActivityDetails(rid: string): Observable<IActivityDetail[]> {
    return this.http.get<IActivityDetail[]>(`${this.endpoint}/research-requests/${rid}/activityDetails`);
  }



  addComment(id: number, message: string): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post<any>(`${this.endpoint}/research-requests/${id}/addComment`, { text: message }, options);
  }

  getFilesFromIRB(rid: number): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/research-requests/${rid}/getFilesFromIRB`);
  }

  remindDataStewards(rid: number) {
    return this.http.get<any>(`${this.endpoint}/research-requests/${rid}/remindDataStewards`);
  }

}
