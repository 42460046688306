import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { AlertsService } from "@edw-app-root/services/alerts.service";
import { AuditsDataService } from "@edw-services/audits.service";
import { LoaderService } from "@edw-services/loader.service";
import { ToastMessageService } from "@edw-services/messaging.service";
import { NotificationsService } from "@edw-services/notifications.service";
import { ToggleNavigationService } from "@edw-services/toggle-navigation.service";
import { UserDataService } from "@edw-services/user-data.service";
import * as moment from 'moment';
import { Subscription } from "rxjs";

@Component({
  selector: "edw-app-wrapper",
  templateUrl: "./app-wrapper.component.html",
  styleUrls: ["./app-wrapper.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppWrapperComponent implements OnInit, OnDestroy {
  private navServiceSubscription: Subscription;
  private unreadNotificationsSubscription: Subscription;
  private loadStatusSubscription: Subscription;
  private hipaaComplianceSubscription: Subscription;
  private newUserMessageSubscription: Subscription;
  private alertServiceSubscription: Subscription;

  private nmSafetyCompletedSubscription: Subscription;
  private nmSafetyNewUserSubscription: Subscription;

  showHipaaComplianceModal = false;
  shownewuserMessageModal = false;
  shownNmSafetyIntegrity = false;
  confirmHipaaCompliance = false;
  isNmSafetyCompleted = true;
  isNmSafetynewuser = false;
  leftNavVisible = true;
  alertsVisible = true;
  isLoadBehind: boolean;
  loadStatus = "";
  domain = "nmhc";
  calendaryear = '2020';



  toggleLeftNav(show) {
    this.leftNavVisible = !this.leftNavVisible;
  }

  constructor(
    private userDataSrevice: UserDataService,
    private loader: LoaderService,
    private notificationsService: NotificationsService,
    private navService: ToggleNavigationService,
    private alertService: AlertsService,
    private toastMessageService: ToastMessageService,
    private auditService: AuditsDataService
  ) {}

  ngOnInit() {
    this.calendaryear = moment(new Date()).format('YYYY');
    //this.calendaryear='2021';
    this.userDataSrevice.userInfoLoadedObservable.subscribe((info) => {
      if (info.loaded === true) {
        this.domain = this.userDataSrevice.getUserDomain();
      }
    });
    this.unreadNotificationsSubscription = this.notificationsService
      .getUnreadCount()
      .subscribe((count) => {
        this.notificationsService.broadcastNotificationsCountChange(count);
      });

    this.loadStatusSubscription = this.notificationsService
      .getLastSystemLoadDate()
      .subscribe((status) => {
        this.notificationsService.broadcastLoadStatustChange(status);
      });

    this.navServiceSubscription = this.navService
      .getObservable()
      .subscribe((info) => {
        if (info.show === true) {
          this.leftNavVisible = true;
        } else {
          this.leftNavVisible = false;
        }
      });

    // this.alertServiceSubscription = this.alertService
    //   .getObservable()
    //   .subscribe((info) => {
    //     console.log(info);
    //     this.alertsVisible = info?.show;
    //   });

    this.newUserMessageSubscription = this.userDataSrevice.isnewUserObservable.subscribe(
      (value) => {
        this.shownewuserMessageModal = value;
      }
    );
    this.userDataSrevice.invokeIsNewUser();

    /*this.hipaaComplianceSubscription = this.userDataSrevice.isHIPAAcompliantObservable.subscribe(
      (value) => {
        //Dont want to display hippa modal along with new user message.
        //it will be triggered when new user click ok in modal
        if (!this.shownewuserMessageModal) {
          this.showHipaaComplianceModal = !value;
        }
      }
    );
    this.userDataSrevice.invokeIsHIPAAcompliant();*/

    this.nmSafetyNewUserSubscription = this.userDataSrevice.isNmSafetyNewUserObservable.subscribe(
      (value) => {
        this.isNmSafetynewuser = value;
      }
    );
    this.userDataSrevice.invokeIsNmSafetyNewUser();

    //dont display safety message for existing users in 2020. we can remove this 'if' next year
    if(!(this.calendaryear=='2020' && !this.isNmSafetynewuser)){
      this.nmSafetyCompletedSubscription = this.userDataSrevice.isNmSafetyCompletedObservable.subscribe(
        (value) => {
          //this.domain = "nmhc";
          if (
          //  !this.showHipaaComplianceModal &&
            !this.shownewuserMessageModal &&
            this.domain == "nmhc"
          ) {
            this.shownNmSafetyIntegrity = !value;
          }
          this.isNmSafetyCompleted = value;
        }
      );
    }


    this.userDataSrevice.invokeIsNmSafetyCompleted();

    this.notificationsService.notificationsLoadStatusObservable.subscribe(
      (status) => {
        this.loadStatus = status.lastLoadDate;
        this.isLoadBehind = status.isBehind;
      }
    );

  }

  ngOnDestroy() {
    this.navServiceSubscription.unsubscribe();
    this.unreadNotificationsSubscription.unsubscribe();
    this.loadStatusSubscription.unsubscribe();
    //this.hipaaComplianceSubscription.unsubscribe();
    //this.alertServiceSubscription.unsubscribe();

  }

  /*skipHipaaCompliance() {
    this.showHipaaComplianceModal = false;
    this.confirmHipaaCompliance = false;
    this.userDataSrevice.invokeIsNmSafetyCompleted();
  }*/

  saveNmSafetyIntegrity(value) {
    this.shownNmSafetyIntegrity = false;

    this.auditService.updateNmSafetyIntegrity(value).subscribe((result) => {
      this.toastMessageService.addMessageObj({
        severity: "success",
        summary: "",
        detail: "Input saved successfuly.",
      });

    });
  }

  newUserMessageUpdate() {
    this.shownewuserMessageModal = false;
    //trigger hippa
   // this.userDataSrevice.invokeIsHIPAAcompliant();
    this.userDataSrevice.invokeIsNmSafetyCompleted();
  }

}
