import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IDataStewardRequest } from '@edw-entities/data-steward-queue';
import { DataStewardQueueService } from '@edw-services/data-steward-queue.service';
import {
  Table
} from 'primeng-lts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-data-steward-queue-list',
  templateUrl: './data-steward-queue-list.component.html',
  styleUrls: ['./data-steward-queue-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataStewardQueueListComponent implements OnInit, OnDestroy {
  public researchRequests: IDataStewardRequest[] = [];
  public searchField: FormControl;
  public searchSub: Subscription;
  public permissions: any;

  private researchRequestsDataSubsription: Subscription;
  @ViewChild('dataTable',{static: true}) dataTable : Table;

  constructor(
    private dataStewardQueueService: DataStewardQueueService,
    private route: ActivatedRoute
  ) {

    this.searchField = new FormControl();
  }

  ngOnInit() {
    this.route.data
      .subscribe((data: { permissions: any, componentData: IDataStewardRequest[] }) => {
        this.permissions = data.permissions;
        this.researchRequests = data.componentData;
      });

    this.searchSub = this.searchField.valueChanges.subscribe((value) => {
      this.search();
    });

    this.researchRequests.forEach(function (value){
      if (value.lastActivity !== null)
       value.lastActivity.displayDate = new Date(value.lastActivity.createdDate).toLocaleString();
    });
  }

  ngOnDestroy() {}

  public lazyLoadMore($event) {
    const lastPage = this.dataTable.totalRecords / this.dataTable.rows;
    const firstIndexOnLastPage = this.dataTable.totalRecords - this.dataTable.rows;
    console.log($event.first, this.dataTable.totalRecords, this.dataTable.rows, firstIndexOnLastPage);
    if ($event.first === firstIndexOnLastPage) {
      // BE returns 100 items per page
      // => first 10 pages on FE are actually the 1st page on BE
      const nextPage = Math.ceil(lastPage / 10) + 1;
      this.dataStewardQueueService
        .getDataStewardQueue(this.searchField.value, nextPage, !this.permissions.canViewAllRequests)
        .subscribe((response) => {
          if (response) {
            const newResearchRequests = response;
            this.researchRequests = this.researchRequests.concat(newResearchRequests);
          }
        });
    }
  }

  public clearSearch(autoCompleteRef) {
    this.searchField.setValue('');
    // the next two lines are here because the autocomplete
    // component doesn't offer a native way to clear the value :(
    autoCompleteRef.inputEL.nativeElement.value = '';
    autoCompleteRef.inputEL.nativeElement.focus();
    this.search();
  }

  public search() {
    this.dataStewardQueueService.getDataStewardQueue(this.searchField.value, 1, !this.permissions.canViewAllRequests)
      .subscribe((response: IDataStewardRequest[]) => {
        this.researchRequests = [];
        if (response) {
          this.researchRequests = response;
        }
      });
    // TODO: error handing here
  }


}
