import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationDomains } from '@edw-app-root/enums/auth-domains';
import { Permissions, hasOverallPermissionsFor, hasSpecificPermissionFor } from '@edw-app-root/permissions';
import { SearchParams } from '@edw-entities/searchParams';
import { User } from '@edw-entities/user';
import { LocalStorageService } from '@edw-services/local-storage.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class UserDataService {
  private userData = {
    isHipaaCompliant: false,
    isNewUser: true,
    systemPermissions: [],
    accounts: [],
    isNmSafetyCompleted: true,
    isNmSafetyNewUser: false
  };

  private userInfoLoadedSubject: Subject<any> = new BehaviorSubject<any>({ loaded: false });
  public userInfoLoadedObservable: Observable<any> = this.userInfoLoadedSubject.asObservable();


  private isHIPAAcompliantSubject: Subject<boolean> = new Subject<boolean>();
  isHIPAAcompliantObservable = this.isHIPAAcompliantSubject.asObservable();

  private isNmSafetyCompletedSubject: Subject<boolean> = new Subject<boolean>();
  isNmSafetyCompletedObservable = this.isNmSafetyCompletedSubject.asObservable();

  private isNmSafetyNewUserSubject: Subject<boolean> = new Subject<boolean>();
  isNmSafetyNewUserObservable = this.isNmSafetyNewUserSubject.asObservable();

  private isnewUserSubject: Subject<boolean> = new Subject<boolean>();
  isnewUserObservable = this.isnewUserSubject.asObservable();

   

  private canManageUsers: boolean;
  private canManageResources: boolean;
  private canManageResourceGroups: boolean;
  private canManageUserGroups: boolean;
  private passedAuthentication = false;

  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService) { }

  public getUserData(id?: any) {
        

    return this.http
      .get(environment.api + '/user/getInfo', {
        observe: 'body'
      })
      .pipe(tap(response => {
        this.userData = response['data'];

        // this.userData.isNewUser=false;
        // this.userData.isHipaaCompliant=false;
        // this.userData.isNmSafetyNewUser=false;
        // this.userData.isNmSafetyCompleted =false;
        this.localStorageService.add('currentUser', this.userData);
        this.userInfoLoadedSubject.next({ loaded: true });
        this.isnewUserSubject.next(this.userData.isNewUser);
        this.isHIPAAcompliantSubject.next(this.userData.isHipaaCompliant);
        this.isNmSafetyCompletedSubject.next(this.userData.isNmSafetyCompleted);
        this.isNmSafetyNewUserSubject.next(this.userData.isNmSafetyNewUser);

      }, error => {
        this.userInfoLoadedSubject.error(error);
      }));
  }

  getUserDomain() {
    let userInfo = this.getCachedUserData();
    let currentUserAccounts = userInfo.accounts;
    let currentUserAccountDomain = userInfo.accounts.map((account) => {
      return account.activeDirectory;
    })[0];

    var loginSSO = this.localStorageService.get('loginSSO');

    //default domain will be nmhc
    var domain = "nmhc";

    //when login SSO is known, user context domain is based solely on domain.
    if (loginSSO != null && loginSSO != "" && (loginSSO == 'nmhc' || loginSSO == 'nu')) {
      if (loginSSO == 'nmhc') {
        domain = "nmhc";
      }
      else {
        domain = "nu";
      }
    } else {
      //when login SSO is not known, it depends on user acounts.
      if (currentUserAccounts.length == 2) {
        //when both accounts are present, the user is assumed nmhc
        domain = "nmhc";
      }
      else if (currentUserAccounts.length == 1) {
        //other wise, it is the sole user account.
        switch (currentUserAccountDomain) {
          case AuthenticationDomains.nmhc:
            domain = "nmhc";
            break;
          case AuthenticationDomains.nwu:
            domain = "nu";
            break;
          default: ;
        }
      }
      else {
        //This is a catch all set to nmhc
        domain = "nmhc";
      }
    }
    return domain;
  }

  invokeIsHIPAAcompliant() {
    this.isHIPAAcompliantSubject.next(this.userData.isHipaaCompliant);
  }

  invokeIsNmSafetyCompleted() {
    this.isNmSafetyCompletedSubject.next(this.userData.isNmSafetyCompleted);
  }

  invokeIsNmSafetyNewUser() {
    this.isNmSafetyNewUserSubject.next(this.userData.isNmSafetyNewUser);
  }

  invokeIsNewUser() {
    this.isnewUserSubject.next(this.userData.isNewUser);
  }

  updateHipaaCompliance(): Observable<Object> {
    return this.http
      .post(environment.api + '/user/updateHipaaCompliance', null, {
        observe: 'body'
      });
  }



  markAuthenticationAsPassed() {
    this.passedAuthentication = true;
  }

  hasPassedAuthentication(): boolean {
    let userInfo;
    let passedAuth = false;

    if (!this.passedAuthentication) {
      if (this.localStorageService.get('currentUser')) {
        userInfo = this.localStorageService.get('currentUser');
      } else {
        userInfo = this.userData;
      }

      if (userInfo) {
        passedAuth = true;
      }
    } else {
      passedAuth = this.passedAuthentication;
    }

    return passedAuth;
  }

  checkIfUserExists(): Observable<Object> {
    return this.http
      .post(environment.api + '/user/isExisting', null, {
        observe: 'body'
      });
  }

  confirmNewAccount(user): Observable<Object> {
    const postBody = user;

    return this.http
      .post(environment.api + '/user/confirmNewAccount', postBody, {
        observe: 'body'
      });
  }

    

  getCachedUserData() {
    return this.userData;
  }



  getOverallPermissions() {
    let userInfo;

    if (this.localStorageService.get('currentUser')) {
      userInfo = this.localStorageService.get('currentUser');
    } else {
      userInfo = this.userData;
    }

    return {
      canManageUsers: hasOverallPermissionsFor(Permissions.USERS, userInfo.systemPermissions),
      canManageResources: hasOverallPermissionsFor(Permissions.RESOURCES, userInfo.systemPermissions),
      canManageResourceGroups: hasOverallPermissionsFor(Permissions.RESOURCES, userInfo.systemPermissions),
      canManageUserGroups: hasOverallPermissionsFor(Permissions.USERS, userInfo.systemPermissions),
    };
  }

  checkForSpecificPermission(permission: string) {
    let userInfo;

    if (this.localStorageService.get('currentUser')) {
      userInfo = this.localStorageService.get('currentUser');
    } else {
      userInfo = this.userData;
    }

    return hasSpecificPermissionFor(permission, userInfo.systemPermissions);
  }


  getAllUsers(query: string, page: number = 1): Observable<Object> {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/user/getAll', options);
  }

  getUserDetails(userId: string): Observable<Object> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get<Response>(environment.api + '/user/' + userId + '/getDetails', options);
  }

  addUser(userObj: User): Observable<Object> {
    const postBody = userObj;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/user/add', postBody, options);
  }

  updateUserFields(userObj: User): Observable<Object> {
    const postBody = userObj;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/user/' + userObj.id + '/update', postBody, options);
  }

  // user groups
  findNonAddedUserGroup = (userId: string, query: string): Observable<Object> => {
    const postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/user/' + userId + '/usergroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;

          if (element.description) {
            element['displayName'] = element['displayName'] + ' - (' + element.description + ')';
          }

          element['link'] = ['/app/manage/user-groups/', element.id];
          return element;
        });

        return response;
      }));
  }

  findAddedUserGroup = (userId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/user/' + userId + '/usergroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;

          if (element.description) {
            element['displayName'] = element['displayName'] + ' - (' + element.description + ')';
          }

          element['link'] = ['/app/manage/user-groups/', element.id];
          return element;
        });

        return response;
      }));
  }

  associateUserWithUserGroups = (userId: string, userGroupIds: number[]): Observable<Object> => {
    const postBody = userGroupIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/user/' + userId + '/usergroup/add', postBody, options);
  }

  removeUserToUserGroupsRelationship = (userId: string, userGroupId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('userGroupId', userGroupId);

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/user/' + userId + '/usergroup/remove', body, options);
  }

  // permission groups
  findNonAddedPermissionGroup = (userId: string, query: string): Observable<Object> => {
    const postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/user/' + userId + '/permissiongroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;

          if (element.description) {
            element['displayName'] = element['displayName'] + ' - (' + element.description + ')';
          }

          element['link'] = ['/app/manage/permission-groups/', element.id];

          return element;
        });

        return response;
      }));
  }

  findAddedPermissionGroup = (userId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/user/' + userId + '/permissiongroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;

          if (element.description) {
            element['displayName'] = element['displayName'] + ' - (' + element.description + ')';
          }

          element['link'] = ['/app/manage/permission-groups/', element.id];

          return element;
        });

        return response;
      }));
  }

  associateUserWithPermissionGroups = (userId: string, permissionGroupIds: number[]): Observable<Object> => {
    const postBody = permissionGroupIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/user/' + userId + '/permissiongroup/add', postBody, options);
  }

  removeUserToPermissionGroupsRelationship = (userId: string, permissionGroupId: string): Observable<Object> => {
    // let params = new HttpParams();
    // params = params.set('permissionGroupId', permissionGroupId);


    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/user/' + userId + '/permissiongroup/remove', parseInt(permissionGroupId, 10), options);
  }

  // resource groups
  findNonAddedResourceGroup = (userId: string, query: string): Observable<Object> => {
    const postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/user/' + userId + '/resourcegroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;

          if (element.description) {
            element['displayName'] = element['displayName'] + ' - (' + element.description + ')';
          }

          element['link'] = ['/app/manage/resource-groups/', element.id];

          return element;
        });

        return response;
      }));
  }

  findAddedResourceGroup = (userId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/user/' + userId + '/resourcegroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;

          if (element.description) {
            element['displayName'] = element['displayName'] + ' - (' + element.description + ')';
          }

          element['link'] = ['/app/manage/resource-groups/', element.id];

          return element;
        });

        return response;
      }));
  }

  associateUserWithResourceGroups = (userId: string, resourceGroupIds: number[]): Observable<Object> => {
    const postBody = resourceGroupIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/user/' + userId + '/resourcegroup/add', postBody, options);
  }

  removeUserToResourceGroupsRelationship = (userId: string, resourceGroupId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('resourceGroupId', resourceGroupId);

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/user/' + userId + '/resourcegroup/remove', body, options);
  }

  // resources
  findNonAddedResource = (userId: string, query: string): Observable<Object> => {
    const postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/user/' + userId + '/resource/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.resourceName + ' (' + element.resourceType + ')';
          return element;
        });

        return response;
      }));
  }

  findAddedResource = (userId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/user/' + userId + '/resource/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.resourceName + ' (' + element.resourceType + ')';
          return element;
        });

        return response;
      }));
  }

  associateUserWithResources = (userId: string, resourceIds: number[]): Observable<Object> => {
    const postBody = resourceIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/user/' + userId + '/resource/add', postBody, options);
  }

  removeUserToResourcesRelationship = (userId: string, resourceId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('resourceId', resourceId);

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/user/' + userId + '/resource/remove', body, options);
  }

  redirectToOpenAM() {
    const body = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/university/login', body, options);
  }

  scheduleMergeUserAccount(sourceUserId: number, targetUserId: number) {
    let params = new HttpParams();
    params = params.set('sourceUserId', sourceUserId.toString());
    params = params.set('targetUserId', targetUserId.toString());

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/user/scheduleMergeUserAccount', body, options);
  }


  cancelMergeUserAccount(mergeAccountId: number) {
    let params = new HttpParams();
    params = params.set('mergeAccountId', mergeAccountId.toString());
    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/user/cancelMergeUserAccount', body, options);
  }

  purplePagesGetAll(query: string, searchById: Boolean): Observable<Object> {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('searchById', searchById.toString());

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/user/purplePages/getAll', options);
  }

  doesUserExist(username: string, domain: string, email: string) {
    let params = new HttpParams();
    params = params.set('username', username);
    params = params.set('domain', domain);
    params = params.set('email', email);

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/user/doesExist', body, options);
  }
}
