import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRestResponse } from '@edw-entities/rest-response';
import { IUserSettings, UserSettings } from '@edw-entities/user-settings';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
//import { initDomAdapter } from '@angular/platform-browser/src/browser';

@Injectable()
export class UserSettingsService {
  private endpoint: string;

  private userSettings: UserSettings = new UserSettings();
  private userSettingSource = new BehaviorSubject(this.userSettings);
  currentUserSettings = this.userSettingSource.asObservable();

  constructor(private http: HttpClient) {
    this.endpoint = environment.api;
    this.initUserSettings();
  }

  initUserSettings() {
    this.getUserSettings()
        .subscribe(response => {
          if (response['data']) {
            this.userSettings.defaultPage = response['data']["defaultPage"];
            this.userSettings.pageView = response['data']["pageView"];
            this.userSettingSource.next(this.userSettings);
          }
      });
  }

  getUserSettings(): Observable<IRestResponse<IUserSettings[]>> {
    let params = new HttpParams();
    const options: Object = {
      observe: 'body',
      params: params
    };
    return this.http
      .get<IRestResponse<IUserSettings[]>>(`${this.endpoint}/usersettings/get`, options);
  }

  updateUserSettings(data: Partial<IUserSettings>) {
    const options: Object = {
      observe: 'body'
    };

    let userSettings = new UserSettings();
    userSettings.defaultPage = data.defaultPage;
    userSettings.pageView = data.pageView;
    this.userSettingSource.next(userSettings);

    return this.http.post<IUserSettings>(`${this.endpoint}/usersettings/update`, data, options);
  }

}
