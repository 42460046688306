import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Permissions } from '@edw-app-root//permissions';
import { ReportsService } from '@edw-services/reports.service';
import { ResourceGroupDataService } from '@edw-services/resource-group.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { StagingReportsService } from '@edw-services/staging-reports.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class ManageResourcesRoutingResolver implements Resolve<any>  {

  constructor(
    public resourceGroupDataService: ResourceGroupDataService,
    public resourceDataService: ReportsService,
    public stagingReportsService: StagingReportsService,
    public sessionStorageService: SessionStorageService,
    public userDataService: UserDataService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const urlFragments = state.url.split('/');
    var lastParam = state.url.split('/')[urlFragments.length - 1];
    var isStaging = '';
    var queryparam ;
    if(lastParam.split('?').length>1){
      queryparam = lastParam.split('?')[1].split("&");
    }
    lastParam = lastParam.split('?')[0];

    if(queryparam!=null && queryparam.length>0){
    queryparam.forEach(element => {
      if(element.indexOf('isStaging')>-1){
        isStaging = element;
      }
    });
  }


    //#region Resource Groups
    if (urlFragments.indexOf('resource-groups') > -1) {

      let permissions = {
        canView: this.userDataService.checkForSpecificPermission(Permissions.RESOURCEGROUPS.canView),
        canCreate: this.userDataService.checkForSpecificPermission(Permissions.RESOURCEGROUPS.canCreate),
        canModifyContent: this.userDataService.checkForSpecificPermission(Permissions.RESOURCEGROUPS.canModifyContent),
        canRemove: this.userDataService.checkForSpecificPermission(Permissions.RESOURCEGROUPS.canRemove),
      };

      let componentData = {
        title: 'Edit a Resource Group',
        permissions: permissions
      };

      if (lastParam === 'resource-groups') {
        return this.resourceGroupDataService.getAllResourceGroups()
          .pipe(
           map(responseBody => {
            //console.log(responseBody);
            componentData['resourceGroups'] = responseBody['data'];
            return componentData;
          }));
      }

      if (lastParam === 'add') {
        componentData.title = 'Add New Resource Group';
        return of(componentData);
      }

      if (!isNaN(parseInt(lastParam, 10)) && parseInt(lastParam, 10) > 0) {
        return this.resourceGroupDataService
          .getResourceGroupDetails(lastParam.toString())
          .pipe(
            map(responseBody => {
            if (responseBody['data']) {
              componentData['resourceGroupDetails'] = responseBody['data'];
            }
            return componentData;
          }));
      }
    }
    //#endregion Resource Groups

    //#region Resources
    if (urlFragments.indexOf('resources') > -1) {

      let permissions = {
        canView: this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canView),
        canCreate: this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canCreate),
        canModifyAssignment: this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canModifyAssignments),
        canRemove: this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canRemove)
      };

      let componentData = {
        title: 'Edit a Resource Group',
        permissions: permissions
      };

      if (lastParam === 'resources') {
        return this.resourceDataService.getAll('',1,true)
        .pipe(
          map(responseBody => {
            componentData['title'] = 'Manage Resources';
            componentData['resources'] = responseBody['data'];
            return componentData;
          }));
      }

      if (!isNaN(parseInt(lastParam, 10)) && parseInt(lastParam, 10) > 0 && urlFragments.indexOf('deployed') > -1) {
        return this.resourceDataService
          .getResourceDetails(lastParam.toString())
          .pipe(
            map(responseBody => {
            if (responseBody['data']) {
              componentData['title'] = 'Edit Resource';
              componentData['resourceDetails'] = responseBody['data'];
            }
            return componentData;
          }));
      }
      if (!isNaN(parseInt(lastParam, 10)) && parseInt(lastParam, 10) > 0 && urlFragments.indexOf('viewsql') > -1) {
        return this.resourceDataService
          .getSQLDetails(lastParam.toString(),isStaging)
          .pipe(
            map(responseBody => {
            if (responseBody['data']) {
              componentData['title'] = 'SQL';
              componentData['resourceDetails'] = responseBody['data'];
            }
            return componentData;
          }));
      }

      if (!isNaN(parseInt(lastParam, 10)) && parseInt(lastParam, 10) > 0 && urlFragments.indexOf('staging') > -1) {
        return this.stagingReportsService
          .getResourceDetails(lastParam.toString())
          .pipe(
            map(responseBody => {
            if (responseBody['data']) {
              componentData['title'] = 'Edit Resource';
              componentData['resourceDetails'] = responseBody['data'];
            }

            return componentData;
          }));
      }
    }
    //#endregion Resources


    if ((urlFragments.indexOf('report-deploy') > -1) || (urlFragments.indexOf('report-redeploy') > -1)) {
      if (!isNaN(parseInt(lastParam, 10)) && parseInt(lastParam, 10) > 0) {
        let permissions = {
          canView: this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canView),
          canCreate: this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canCreate),
          canModifyAssignment: this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canModifyAssignments),
          canRemove: this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canRemove),
        };

        console.log(permissions);

        let componentData = {
          title: 'Report Deploy',
          permissions: permissions
        };

        return this.stagingReportsService
          .getFullDeployDetails(lastParam.toString())
          .pipe(
            map(responseBody => {
            if (responseBody['data']) {

              if (this.sessionStorageService.get('reportDeployWizard')) {
                const cachedResourceDetails = this.sessionStorageService.get('reportDeployWizard');
                // we always get the approvers from the server since they might have been updated
                cachedResourceDetails.data.security.approvers.collection = responseBody['data'].approvers;

                // we always get the related entities from the server since they might have been updated
                cachedResourceDetails.data.security.userGroups.collection = responseBody['data'].resourceDeploy.userGroups;
                cachedResourceDetails.data.security.resourceGroups.collection = responseBody['data'].resourceDeploy.resourceGroups;
                cachedResourceDetails.data.security.users.collection = responseBody['data'].resourceDeploy.users;

                componentData['cachedResourceDetails'] = cachedResourceDetails.data;
              } else {
                componentData['resourceDetails'] = responseBody['data'];
              }
            }

            return componentData;
          }));
      }
    }

  }
}

